import React, { useState } from 'react';
import styles from './styles.module.scss';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikController from '../../components/inputFields/formikController';
import Button from '../../components/button';
import { getMobileVerification, logout } from '../../redux/actions';
import PropTypes from 'prop-types';
import { getAuthHeader } from '../../utils/auth';
import axios from '../../utils/api';
import { useHistory } from 'react-router';
import nurtureBoxLogo from '../../assets/images/nurturebox-full.svg';

const CompanyDetails = ({
  getMobileVerification,
  setErrorCode,
  companyName,
  logout
}) => {
  const [submitting, setSubmitting] = useState(false);

  const history = useHistory();
  const companySize = [
    {
      label: '1-4',
      name: '4'
    },
    {
      label: '5-19',
      name: '19'
    },
    {
      label: '20-49',
      name: '49'
    },
    {
      label: '50-99',
      name: '99'
    },
    {
      label: '99+',
      name: '150'
    }
  ];
  const initialValues = {
    company: companyName,
    role: '',
    size: ''
  };

  const validationSchema = Yup.object().shape({
    company: Yup.string().required('Company name is required')
  });

  const getCompanyDetails = async ({ values: { company, role, size } }) => {
    try {
      setSubmitting(true);
      const body = {
        companyName: company,
        size: size && size.name,
        job_role: role,
        isNewUser: false
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/extension/user/update`,
        body,
        getAuthHeader()
      );
      console.log({ response }, 'comp dets res');
      if (!response?.data?.error) {
        history.push({
          pathname: '/onboard-success',
          state: {
            tourCompleted: !!response?.data?.tour?.tour_steps_completed
          }
        });
        getMobileVerification(true);
      } else {
        setErrorCode(500);
      }
    } catch (err) {
      console.log({ err });
      if (err.response.status === 401) {
        logout();
      }
      if (err.response.status === 404 || err.response.status === 400) {
        setErrorCode(err.response.status);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className={styles.inputSection}>
        <img
          src={nurtureBoxLogo}
          alt="nurturebox"
          className={styles.nurtureBoxImg}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {formik => (
            <Form>
              <div className={styles.formParent}>
                <div className={styles.phoneVerification}>
                  <h4>Thanks for signing up✨✨</h4>
                  <p className={styles.subHeading}>
                    Next step: A quick survey to help us customise your sourcing
                  </p>
                  <FormikController
                    control="text"
                    name="company"
                    placeholder="Enter Company name"
                    variant="filled"
                    label="Company name"
                    value={companyName}
                  />
                  <FormikController
                    control="select"
                    name="size"
                    placeholder="Company size"
                    variant="filled"
                    label="Company size"
                    options={companySize}
                    value={initialValues.size || formik.values.size}
                  />
                  <FormikController
                    control="text"
                    name="role"
                    placeholder="Your role"
                    variant="filled"
                    label="What is your role?"
                  />
                  <div className={styles.formButton}>
                    <Button
                      label={submitting ? 'Submitting...' : 'Proceed'}
                      className={styles.submitBtn}
                      clickHandler={() => getCompanyDetails(formik)}
                      disabled={submitting || formik.values.company < 1}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.token
  };
};

CompanyDetails.propTypes = {
  isExtensionInstalled: PropTypes.string
};

export default connect(mapStateToProps, {
  getMobileVerification,
  logout
})(CompanyDetails);
