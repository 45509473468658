import React from 'react';
import styles from './styles.module.scss';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import FormikController from '../../components/inputFields/formikController';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Button from '../../components/button';
import { useState, useEffect } from 'react';
import axios from '../../utils/api';
import { COUNTRYCODE } from '../../utils/constants';
import { getAuthHeader } from '../../utils/auth';
import { updateUser } from '../../redux/actions';
import nurtureBoxLogo from '../../assets/images/nurturebox-full.svg';

const UserDetails = ({
  values,
  setErrorCode,
  handleChange,
  nextStep,
  updateUser
}) => {
  const { companyName, first_name, last_name, phone_number } = values;
  const [phone, setPhone] = useState(phone_number || '');
  const [submitting, setSubmitting] = useState(false);
  const [isPhoneConflictError, setIsPhoneConflictError] = useState(false);

  let initialValues = {
    companyName: companyName,
    first_name: first_name,
    last_name: last_name,
    phone_number: phone
  };
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    companyName: Yup.string().required('Company Name is required'),
    phone_number: Yup.number()
      .integer()
      .typeError('Please Enter a valid phone number')
      .required('Phone number is required'),

    last_name: Yup.string()
  });
  const nextForm = async e => {
    setSubmitting(true);
    handleChange({ ...e, phone_number: phone });
    const body = {
      first_name: e.first_name,
      last_name: e.last_name,
      phone_number: phone
    };
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/onboard/user`,
        body,
        getAuthHeader()
      );
      setSubmitting(false);
      nextStep();
      updateUser({ ...body });
    } catch (err) {
      console.log(err);
      setSubmitting(false);
      if (err.response.status === 409) {
        setIsPhoneConflictError(true);
      } else {
        setErrorCode(err.response.status);
      }
    }
  };

  const [countryCode, setCountryCode] = useState('');
  useEffect(() => {
    axios
      .get(COUNTRYCODE)
      .then(res => {
        const code = res.data['country_code'];
        setCountryCode(code);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handlePhoneChange = (num = '') => {
    if (isPhoneConflictError) setIsPhoneConflictError(false);
    setPhone(num);
  };

  return (
    <>
      <div className={styles.inputSection}>
        <img
          src={nurtureBoxLogo}
          alt="nurturebox"
          className={styles.nurtureBoxImg}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
        >
          {formik => (
            <Form>
              <div className={styles.formParent}>
                <div className={styles.postForm}>
                  <h4>Create an account</h4>
                  <p className={styles.subHeading}>
                    Create an account with NurtureBox
                  </p>
                  <div className={styles.loginNAme}>
                    <div className={styles.firstName}>
                      <FormikController
                        control="text"
                        name="first_name"
                        placeholder="First name"
                        variant="filled"
                        label="Full name"
                      />
                    </div>
                    <div className={styles.lastName}>
                      <FormikController
                        control="text"
                        name="last_name"
                        placeholder="Last name"
                        variant="filled"
                      />
                    </div>
                  </div>
                  <div className={styles.numberField}>
                    <label htmlFor="Contact" className={styles.label}>
                      Contact (Optional)
                    </label>
                    <PhoneInput
                      className={styles.phoneNumberInput}
                      defaultCountry={countryCode}
                      name="phone_number"
                      placeholder="Enter your phone number"
                      value={phone_number}
                      onChange={handlePhoneChange}
                      maxLength="16"
                    />
                    {isPhoneConflictError && (
                      <p className={styles.otpError}>
                        Phone number already in use
                      </p>
                    )}
                  </div>
                  <div className={styles.formButton}>
                    <Button
                      className={styles.submitBtn}
                      type="submit"
                      clickHandler={() => nextForm(formik.values)}
                      disabled={
                        (phone && phone.length < 10) ||
                        formik.values.first_name.length < 3 ||
                        submitting
                      }
                      label="Next"
                    />

                    <p className={styles.disclaimer}>
                      By signing up for our service, you agree to our{' '}
                      <a
                        href={process.env.REACT_APP_PRIVACY_POLICY}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms & Conditions
                      </a>{' '}
                      and acknowledge that you have read our Privacy Policy.
                    </p>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default connect(null, { updateUser })(UserDetails);
