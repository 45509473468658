import React from 'react';
import styles from './styles.module.scss';
import chromeIcon from '../../assets/images/chrome.svg';
import extensionImg from '../../assets/images/extension.svg';
import Button from '../../components/button';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { redirectToStore } from '../../utils/common';
import { logout } from '../../redux/actions';

const ChromeExtension = ({ token, logout }) => {
  const [, setCookie] = useCookies(['authToken']);
  setCookie('authToken', token, {
    path: '/'
  });

  return (
    <>
      <section className={styles.chromeSection}>
        <div className={styles.innerContainer}>
          <h1>Add NurtureBox to chrome</h1>
          <p>
            With NurtureBox, You can instantly get the context about the
            candidate right on the recruitment channels{' '}
            <span onClick={logout}>you</span> already use.
          </p>
          <img
            className={styles.extensionImg}
            src={extensionImg}
            alr="ext-instruction"
          />
          <div className={styles.extensionButton}>
            <Button
              label="Add NurtureBox for Chrome"
              startIcon={
                <img
                  src={chromeIcon}
                  alt="chrome"
                  className={styles.chromeBtn}
                />
              }
              className={styles.chromeButton}
              clickHandler={redirectToStore}
            />
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.token
  };
};

ChromeExtension.propTypes = {
  token: PropTypes.string
};

export default connect(mapStateToProps, { logout })(ChromeExtension);
