import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import Button from '../../components/button';
import Loader from '../../components/loader';
import { login, returnErrors } from '../../redux/actions';

import logInImage from '../../assets/images/loginpage-img.png';
import googleLogo from '../../assets/images/white-bg-chrome.svg';

import styles from './styles.module.scss';

const LoginPage = ({
  login,
  // returnErrors,
  isLoading,
  username,
  token,
  loginError
}) => {
  const history = useHistory();
  const oAuthSuccess = async response => {
    const { isNewUser } = await login(response.code);
    history.push(isNewUser ? '/onboard' : '/onboard-success');
  };

  // const oAuthError = response => {
  //   // TODO set error in store
  //   console.log({response});
  //   returnErrors({ message: response.error, code: 500 });
  // };

  // if (username && token) {
  //   console.log('in redirect');
  //   // history.push('/');
  //   return <Redirect to="/onboard-success" />;
  // }

  return (
    <>
      {token && username ? (
        <Redirect to="/onboard-success" />
      ) : (
        <>
          {isLoading && <Loader />}
          <section
            className={`${styles.page} ${isLoading ? styles.loginLoading : ''}`}
          >
            <div className={styles.loginBox}>
              <div className={styles.loginBoxInner}>
                <div>
                  <img
                    src={logInImage}
                    alt="Logo"
                    className={styles.logInImage}
                  />
                  <p className={styles.mainText}>
                    We’re excited to see you onboard.
                  </p>
                  <p className={styles.miniText}>
                    Thanks for signing up. we’re excited to help your fly
                    through your recruitment twice as fast as before.
                  </p>
                </div>
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  onSuccess={oAuthSuccess}
                  // onFailure={oAuthError}
                  responseType="code"
                  accessType="offline"
                  prompt="consent"
                  render={({ onClick }) => (
                    <Button
                      variant="bordered"
                      className={styles.loginBtn}
                      startIcon={
                        <img
                          src={googleLogo}
                          className={styles.googleBtnIcon}
                          alt="Google"
                        />
                      }
                      clickHandler={onClick}
                      label="Continue with google"
                    />
                  )}
                />
                {loginError ? (
                  <p className={styles.errorMessage}>
                    {loginError} or write to us{' '}
                    <a href="mailto:support@nurturebox.ai">
                      support@nurturebox.ai
                    </a>
                  </p>
                ) : null}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  username: state.auth.username,
  isLoading: state.auth.isLoading,
  token: state.auth.token,
  loginError: state.error?.message
});

export default connect(mapStateToProps, {
  login,
  returnErrors
})(LoginPage);
