import axios from 'axios';
import { getAuthTimeZone } from './auth';
import { checkExtension } from './common';

export const validateUser = async token => {
  let data = { isValid: true, isNewUser: false, user: null };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/extension/user`,
      getAuthTimeZone()
    );
    checkExtension(token);
    data.isValid = true;
    data.isNewUser = !!res.data.isNewUser;
    data.user = res.data;
  } catch (err) {
    if (err.response?.data?.code === 401) {
      data.isValid = false;
    }
  }
  return data;
};

export const getTourLink = async token => {
  let link = '';
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/extension/app-settings/tour`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    link = res?.data?.[0]?.tourSetting?.[0]?.settings?.searchUrl;
  } catch (error) {
    console.log('error in getting tour link');
  }
  return link;
};
