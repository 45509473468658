import {
  GET_LISTS_SUCCESS,
  GET_LISTS_FAILED,
  GET_LISTS_LOADING,
  CREATE_LIST_SUCCESS,
  CREATE_LIST_FAILED,
  CREATE_LIST_LOADING,
  DELETE_LIST_SUCCESS,
  DELETE_LIST_FAILED,
  DELETE_LIST_LOADING,
  GET_DROPDOWN_LIST_SUCCESS,
  GET_DROPDOWN_LIST_FAILED,
  GET_DROPDOWN_LIST_LOADING,
  CLEAR_RESPONSE_STATUS
} from '../actionTypes/listPage';

const initialState = {
  initialList: {
    data: [],
    isLoading: false,
    hasErrors: false,
    totalCount: 0
  },
  newList: {
    data: null,
    isLoading: false,
    hasErrors: false,
    isSuccess: undefined,
    errorCode: null,
    status: 'passive'
  },
  dropdownList: {
    data: [],
    isLoading: false,
    hasErrors: false
  },
  deleteList: {
    data: [],
    isLoading: false,
    hasErrors: false,
    isSuccess: undefined,
    errorCode: null,
    status: 'passive'
  }
};

const listPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LISTS_LOADING: {
      const { changeCount } = action.payload;
      return {
        ...state,
        initialList: {
          ...state.initialList,
          isLoading: true,
          totalCount: changeCount ? 0 : state.initialList.totalCount
        }
      };
    }
    case GET_LISTS_SUCCESS: {
      const { data, total } = action.payload;
      return {
        ...state,
        initialList: {
          data,
          isLoading: false,
          hasErrors: false,
          totalCount: total
        }
      };
    }
    case GET_LISTS_FAILED: {
      return {
        ...state,
        initialList: {
          data: [],
          isLoading: false,
          hasErrors: true,
          totalCount: 0
        }
      };
    }
    case CREATE_LIST_LOADING: {
      return {
        ...state,
        newList: {
          ...state.newList,
          isLoading: true,
          status: 'loading'
        }
      };
    }
    case CREATE_LIST_SUCCESS: {
      return {
        ...state,
        initialList: {
          ...state.initialList,
          data: [action.payload, ...state.initialList.data],
          totalCount: state.initialList.totalCount + 1
        },
        newList: {
          data: action.payload,
          isLoading: false,
          hasErrors: false,
          isSuccess: true,
          status: 'success'
        }
      };
    }
    case CREATE_LIST_FAILED: {
      return {
        ...state,
        newList: {
          data: [],
          isLoading: false,
          hasErrors: true,
          isSuccess: false,
          errorCode: action.payload,
          status: 'fail'
        }
      };
    }
    case DELETE_LIST_LOADING: {
      return {
        ...state,
        deleteList: {
          ...state.deleteList,
          isLoading: true,
          status: 'loading'
        }
      };
    }
    case DELETE_LIST_SUCCESS: {
      const listIds = action.payload.listIds;
      const updateListData = state.initialList.data.filter(
        item => !listIds.includes(item._id)
      );

      return {
        ...state,
        initialList: {
          ...state.initialList,
          data: [...updateListData],
          totalCount: state.initialList.totalCount - listIds.length
        },
        deleteList: {
          data: updateListData,
          isLoading: false,
          hasErrors: false,
          isSuccess: true,
          status: 'success'
        }
      };
    }
    case DELETE_LIST_FAILED: {
      return {
        ...state,
        deleteList: {
          isLoading: false,
          hasErrors: true,
          isSuccess: false,
          errorCode: action.payload,
          status: 'fail'
        }
      };
    }

    case GET_DROPDOWN_LIST_LOADING: {
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          isLoading: true
        }
      };
    }
    case GET_DROPDOWN_LIST_SUCCESS: {
      const { data, total } = action.payload;
      return {
        ...state,
        dropdownList: {
          data,
          isLoading: false,
          hasErrors: false,
          totalCount: total
        }
      };
    }
    case GET_DROPDOWN_LIST_FAILED: {
      return {
        ...state,
        dropdownList: {
          data: [],
          isLoading: false,
          hasErrors: true,
          totalCount: 0
        }
      };
    }
    case CLEAR_RESPONSE_STATUS: {
      return {
        ...state,
        newList: {
          ...state.newList,
          isSuccess: undefined,
          hasErrors: undefined,
          status: 'passive',
          errorCode: null
        },
        deleteList: {
          ...state.deleteList,
          isSuccess: undefined,
          hasErrors: undefined,
          status: 'passive',
          errorCode: null
        }
      };
    }
    default:
      return state;
  }
};

export default listPageReducer;
