import React from 'react';
import { Field } from 'formik';
import TextInput from './textInput';
import Select from './select';
import FileInput from './fileUpload';

const FormikController = props => {
  const { control, ...rest } = props;
  switch (control) {
    case 'text':
    case 'email':
      return <Field component={TextInput} {...rest} />;
    case 'select':
      return <Field component={Select} {...rest} />;
    case 'textarea':
      return null;
    case 'file':
    case 'image':
      return <Field component={FileInput} {...rest} />;
    case 'checkbox':
      return null;
    case 'radio':
      return null;
    case 'date':
      return null;
    default:
      return null;
  }
};

export default FormikController;
