import {
  CREATE_PROSPECT_SUCCESS,
  CREATE_PROSPECT_FAILED,
  CREATE_PROSPECT_LOADING,
  CREATE_PROSPECT_LIST_LOADING,
  CREATE_PROSPECT_LIST_SUCCESS,
  CREATE_PROSPECT_LIST_FAILED,
  CREATE_PROSPECT_SEQUENCE_LOADING,
  CREATE_PROSPECT_SEQUENCE_SUCCESS,
  CREATE_PROSPECT_SEQUENCE_FAILED,
  GET_PROSPECTS_SUCCESS,
  GET_PROSPECTS_FAILED,
  GET_PROSPECTS_LOADING,
  CLEAR_RESPONSE_STATUS,
  DELETE_LIST_PROSPECT_LOADING,
  DELETE_LIST_PROSPECT_SUCCESS,
  DELETE_LIST_PROSPECT_FAILED
} from '../actionTypes/prospectsPage';

const initialState = {
  prospectsList: {
    data: [],
    totalProspects: 0,
    isLoading: false,
    hasErrors: false
  },
  newPropspect: {
    data: [],
    hasErrors: false,
    isLoading: false,
    isSuccess: undefined,
    status: 'passive'
  },
  deleteProspectsList: {
    data: [],
    isLoading: false,
    hasErrors: false,
    isSuccess: undefined,
    errorCode: null,
    status: 'passive'
  },
  createProspectList: {
    data: [],
    isLoading: false,
    hasErrors: false,
    isSuccess: undefined,
    errorCode: null,
    status: 'passive'
  },
  createProspectSequence: {
    data: [],
    isLoading: false,
    hasErrors: false,
    isSuccess: undefined,
    errorCode: null,
    status: 'passive'
  }
};

const prospectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROSPECTS_LOADING: {
      const { changeCount } = action.payload;

      return {
        ...state,
        prospectsList: {
          ...state.prospectsList,
          isLoading: true,
          totalProspects: changeCount ? 0 : state.prospectsList.totalProspects
        }
      };
    }
    case GET_PROSPECTS_SUCCESS: {
      return {
        ...state,
        prospectsList: {
          data: [...action.payload.data],
          totalProspects: action.payload.totalCount[0].total,
          isLoading: false,
          hasErrors: false
        }
      };
    }
    case GET_PROSPECTS_FAILED: {
      return {
        ...state,
        prospectsList: {
          data: [],
          totalProspects: 0,
          isLoading: false,
          hasErrors: true
        }
      };
    }
    case CREATE_PROSPECT_LOADING: {
      return {
        ...state,
        newPropspect: {
          ...state.newPropspect,
          isLoading: true,
          status: 'loading'
        }
      };
    }
    case CREATE_PROSPECT_SUCCESS: {
      let updated = [];
      const updatedProspect = action.payload && action.payload;
      const index = state.prospectsList.data.findIndex(
        p => p._id === updatedProspect._id
      );
      if (index < 0) {
        updated = [updatedProspect, ...state.prospectsList.data];
      } else {
        const data = [...state.prospectsList.data];
        data[index] = updatedProspect;
        updated = [...data];
      }

      return {
        ...state,
        prospectsList: {
          ...state.prospectsList,
          data: updated,
          totalProspects:
            index < 0
              ? state.prospectsList.totalProspects + 1
              : state.prospectsList.totalProspects
        },
        newPropspect: {
          data: action.payload,
          isLoading: false,
          hasErrors: false,
          isSuccess: true,
          status: 'success'
        }
      };
    }

    case CREATE_PROSPECT_FAILED: {
      return {
        ...state,
        newPropspect: {
          data: null,
          hasErrors: true,
          isLoading: false,
          isSuccess: false,
          errorCode: action.payload,
          status: 'fail'
        }
      };
    }
    case CREATE_PROSPECT_LIST_LOADING: {
      return {
        ...state,
        createProspectList: {
          ...state.createProspectList,
          isLoading: true,
          status: 'loading'
        }
      };
    }
    case CREATE_PROSPECT_LIST_SUCCESS: {
      return {
        ...state,
        prospectsList: {
          data: [...state.prospectsList.data],
          totalProspects: state.prospectsList.totalProspects
        },
        createProspectList: {
          data: [action.payload],
          hasErrors: false,
          isLoading: false,
          isSuccess: true,
          status: 'success'
        }
      };
    }

    case CREATE_PROSPECT_LIST_FAILED: {
      return {
        ...state,
        createProspectList: {
          hasErrors: true,
          isLoading: false,
          isSuccess: false,
          errorCode: action.payload,
          status: 'fail'
        }
      };
    }
    case CREATE_PROSPECT_SEQUENCE_LOADING: {
      return {
        ...state,
        createProspectSequence: {
          ...state.createProspectSequence,
          isLoading: true,
          status: 'loading'
        }
      };
    }
    case CREATE_PROSPECT_SEQUENCE_SUCCESS: {
      return {
        ...state,
        prospectsList: {
          data: [...state.prospectsList.data],
          totalProspects: state.prospectsList.totalProspects
        },
        createProspectSequence: {
          data: [action.payload],
          hasErrors: false,
          isLoading: false,
          isSuccess: true,
          status: 'success'
        }
      };
    }

    case CREATE_PROSPECT_SEQUENCE_FAILED: {
      return {
        ...state,
        createProspectSequence: {
          hasErrors: true,
          isLoading: false,
          isSuccess: false,
          errorCode: action.payload,
          status: 'fail'
        }
      };
    }
    case CLEAR_RESPONSE_STATUS: {
      return {
        ...state,
        newPropspect: {
          ...state.newPropspect,
          isSuccess: undefined,
          hasErrors: undefined,
          status: 'passive',
          errorCode: null
        },
        deleteProspectsList: {
          ...state.deleteProspectsList,
          isSuccess: undefined,
          hasErrors: undefined,
          status: 'passive',
          errorCode: null
        },
        createProspectList: {
          ...state.createProspectList,
          isSuccess: undefined,
          hasErrors: undefined,
          status: 'passive',
          errorCode: null
        },
        createProspectSequence: {
          ...state.createProspectSequence,
          isSuccess: undefined,
          hasErrors: undefined,
          status: 'passive',
          errorCode: null
        }
      };
    }
    case DELETE_LIST_PROSPECT_LOADING: {
      return {
        ...state,
        deleteProspectsList: {
          ...state.deleteProspectsList,
          isLoading: true,
          status: 'loading'
        }
      };
    }
    case DELETE_LIST_PROSPECT_SUCCESS: {
      const { personIds } = action.payload;
      const updateListProspectsData = state.prospectsList.data.filter(
        item => !personIds.includes(item._id)
      );

      return {
        ...state,
        prospectsList: {
          ...state.prospectsList,
          data: [...updateListProspectsData],
          totalProspects: state.prospectsList.totalProspects - personIds.length
        },
        deleteProspectsList: {
          data: updateListProspectsData,
          isLoading: false,
          hasErrors: false,
          isSuccess: true,
          status: 'success'
        }
      };
    }
    case DELETE_LIST_PROSPECT_FAILED: {
      return {
        ...state,
        deleteProspectsList: {
          isLoading: false,
          hasErrors: true,
          isSuccess: false,
          errorCode: action.payload,
          status: 'fail'
        }
      };
    }
    default:
      return state;
  }
};

export default prospectsReducer;
