import React, { useEffect } from 'react';
import './App.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from './pages/login';
import AuthRoute from './utils/authRoute';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PostLogin from './pages/postLogin';
import Feedback from './pages/feedback';
import { logout, updateUser } from './redux/actions';
import { validateUser } from './utils/helpers';
import OnboardSuccess from './pages/onboardSuccess';
function App({ token, logout, updateUser }) {
  const [, , removeCookie] = useCookies(['authToken']);

  useEffect(() => {
    if (!token) {
      removeCookie('authToken');
      return;
    }
    validateUser(token).then(data => {
      if (!data?.isValid) {
        logout();
      }
      if (data?.user?._id) updateUser(data.user);
    });
  }, [token]);

  return (
    <Switch>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/feedback">
        <Feedback />
      </Route>
      <AuthRoute path="/onboard">
        <PostLogin />
      </AuthRoute>
      <AuthRoute path="/onboard-success">
        <OnboardSuccess />
      </AuthRoute>
      <Route path="/">
        <Redirect to="/login" />
        {/* <LoginPage /> */}
      </Route>
    </Switch>
  );
}
const mapStateToProps = state => {
  return {
    token: state.auth.token
  };
};
App.propTypes = {
  token: PropTypes.string
};
export default connect(mapStateToProps, { logout, updateUser })(App);
