import {
  GET_SEQUENCES_SUCCESS,
  GET_SEQUENCES_FAILED,
  GET_SEQUENCES_LOADING,
  GET_DROPDOWN_SEQUENCES_SUCCESS,
  GET_DROPDOWN_SEQUENCES_FAILED,
  GET_DROPDOWN_SEQUENCES_LOADING,
  SET_TEMPORARY_SEQUENCE_TITLE,
  CLEAR_TEMPORARY_SEQUENCE_TITLE,
  CREATE_SEQUENCES_LOADING,
  CREATE_SEQUENCES_SUCCESS,
  CREATE_SEQUENCES_FAILED,
  DELETE_SEQUENCES_LOADING,
  DELETE_SEQUENCES_SUCCESS,
  DELETE_SEQUENCES_FAILED,
  CLEAR_SEQUENCE_RESPONSE_STATUS
} from '../actionTypes/sequence';

const initialState = {
  temporaryTitle: '',
  initialSequences: {
    data: [],
    isLoading: false,
    hasErrors: false,
    totalCount: 0,
    status: 'passive'
  },
  dropdownSequences: {
    data: [],
    isLoading: false,
    hasErrors: false
  },
  createSequence: {
    data: {},
    isLoading: false,
    hasErrors: false,
    isSuccess: undefined,
    status: 'passive',
    errorCode: null
  },
  deleteSequence: {
    data: [],
    isLoading: false,
    hasErrors: false,
    isSuccess: undefined,
    errorCode: null,
    status: 'passive'
  }
};

const sequenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEQUENCES_LOADING: {
      const { changeCount } = action.payload;
      return {
        ...state,
        initialSequences: {
          ...state.initialSequences,
          isLoading: true,
          totalCount: changeCount ? 0 : state.initialSequences.totalCount
        }
      };
    }
    case GET_SEQUENCES_SUCCESS: {
      const { data, total } = action.payload;
      return {
        ...state,
        initialSequences: {
          data,
          isLoading: false,
          hasErrors: false,
          totalCount: total
        }
      };
    }
    case GET_SEQUENCES_FAILED: {
      return {
        ...state,
        initialSequences: {
          data: [],
          isLoading: false,
          hasErrors: true,
          totalCount: 0
        }
      };
    }
    case GET_DROPDOWN_SEQUENCES_LOADING: {
      return {
        ...state,
        dropdownSequences: {
          ...state.dropdownSequences,
          isLoading: true
        }
      };
    }
    case GET_DROPDOWN_SEQUENCES_SUCCESS: {
      return {
        ...state,
        dropdownSequences: {
          data: [...action.payload],
          isLoading: false,
          hasErrors: false
        }
      };
    }
    case GET_DROPDOWN_SEQUENCES_FAILED: {
      return {
        ...state,
        dropdownSequences: {
          data: [],
          isLoading: false,
          hasErrors: true
        }
      };
    }
    case CREATE_SEQUENCES_LOADING: {
      return {
        ...state,
        createSequence: {
          ...state.createSequence,
          isLoading: true,
          status: 'loading'
        }
      };
    }
    case CREATE_SEQUENCES_SUCCESS: {
      return {
        ...state,
        createSequence: {
          data: action.payload,
          isLoading: false,
          hasErrors: false,
          isSuccess: true,
          status: 'success'
        }
      };
    }
    case CREATE_SEQUENCES_FAILED: {
      return {
        ...state,
        createSequence: {
          data: [],
          isLoading: false,
          hasErrors: true,
          isSuccess: false,
          status: 'fail',
          errorCode: action.payload
        }
      };
    }
    case DELETE_SEQUENCES_LOADING: {
      return {
        ...state,
        deleteSequence: {
          ...state.deleteSequence,
          isLoading: true,
          status: 'loading'
        }
      };
    }
    case DELETE_SEQUENCES_SUCCESS: {
      const sequenceIds = action.payload.sequenceIds;
      const updateSequenceData = state.initialSequences.data.filter(
        item => !sequenceIds.includes(item._id)
      );

      return {
        ...state,
        initialSequences: {
          ...state.initialSequences,
          data: [...updateSequenceData],
          totalCount: state.initialSequences.totalCount - sequenceIds.length
        },
        deleteSequence: {
          data: updateSequenceData,
          isLoading: false,
          hasErrors: false,
          isSuccess: true,
          status: 'success'
        }
      };
    }
    case DELETE_SEQUENCES_FAILED: {
      return {
        ...state,
        deleteSequence: {
          isLoading: false,
          hasErrors: true,
          isSuccess: false,
          errorCode: action.payload,
          status: 'fail'
        }
      };
    }
    case SET_TEMPORARY_SEQUENCE_TITLE: {
      return {
        ...state,
        temporaryTitle: action.payload.title
      };
    }
    case CLEAR_TEMPORARY_SEQUENCE_TITLE: {
      return {
        ...state,
        temporaryTitle: ''
      };
    }
    case CLEAR_SEQUENCE_RESPONSE_STATUS: {
      return {
        ...state,
        createSequence: {
          ...state.createSequence,
          isSuccess: undefined,
          hasErrors: undefined,
          status: 'passive',
          errorCode: null
        },
        deleteSequence: {
          ...state.deleteSequence,
          isSuccess: undefined,
          hasErrors: undefined,
          status: 'passive',
          errorCode: null
        }
      };
    }
    default:
      return state;
  }
};

export default sequenceReducer;
