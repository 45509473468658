export const getAuthHeader = () => {
  const token = localStorage.getItem('authToken');
  return { headers: { Authorization: `Bearer ${token}` } };
};

export const getAuthTimeZone = () => {
  const token = localStorage.getItem('authToken');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  };
};
