import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { updateExtensionState } from '../../redux/actions';
import { getTourLink } from '../../utils/helpers';
import ChromeExtension from '../chromeExtension';
import LinkedInExtension from '../linkedInExtension';

const OnboardSuccess = ({
  updateExtensionState,
  isExtensionInstalled,
  token,
  user
}) => {
  const [tourInfo, setTourInfo] = useState({ showTour: false, tourLink: '' });

  useEffect(async () => {
    if (!user) return;
    if (
      token &&
      (user?.tour_steps_completed || user?.tour?.tour_steps_completed)
    )
      return;
    setTourInfo({ showTour: true, tourLink: '' });
    const link = await getTourLink(token);
    if (link) {
      setTourInfo({ showTour: true, tourLink: link });
    }
  }, [user, token]);

  useEffect(() => {
    updateExtensionState();
  }, []);

  return isExtensionInstalled ? (
    <LinkedInExtension {...tourInfo} />
  ) : (
    <ChromeExtension />
  );
};

const mapStateToProps = state => ({
  isExtensionInstalled: state.auth.isExtensionInstalled,
  token: state.auth.token,
  user: state.auth.user
});

export default connect(mapStateToProps, { updateExtensionState })(
  OnboardSuccess
);
