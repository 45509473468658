import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * An HOC that allows access to protected routes only when a user logs in
 * @param {Object} Page - The particular protected page
 * @param {String} username - The currently logged in user fetched from Redux store and passed as prop
 * @param {Object} rest - Additional props supplied to the route
 *
 * @author Karthik
 */
const AuthRoute = ({ children, username, token, ...rest }) => {
  const { pathname } = useLocation();
  return (
    <Route
      {...rest}
      render={() => (
        <>
          {username && token ? (
            children
          ) : (
            <Redirect to={{ pathname: 'login', state: { from: pathname } }} />
          )}
        </>
      )}
    />
  );
  // return (
  //   <>
  //     <Route
  //       {...rest}
  //       render={() =>
  //         username ? (
  //           <>
  //             {hasExtension ? (
  //               <LinkedinExtension />
  //             ) : (
  //               <ChromeExtension token={token} />
  //             )}
  //           </>
  //         ) : (
  //           <Redirect
  //             to={{ pathname: '/login', state: { referrer: pathname } }}
  //           />
  //         )
  //       }
  //     />
  //   </>
  // );
};

const mapStateToProps = state => {
  return {
    username: state.auth.username,
    token: state.auth.token
  };
};

AuthRoute.propTypes = {
  username: PropTypes.string,
  rest: PropTypes.object,
  token: PropTypes.string
};

export default connect(mapStateToProps)(AuthRoute);
