import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import Button from '../../components/button';
import feedBack from '../../assets/images/faq.svg';
import axios from '../../utils/api';
import { logout } from '../../redux/actions';
import { connect } from 'react-redux';
import { isArrayEmpty } from '../../utils/common';
import { useLocation } from 'react-router-dom';
import nurtureBoxLogo from '../../assets/images/nurturebox-full.svg';
import thankyou from '../../assets/images/thankyou2.svg';

const Feedback = ({ logout }) => {
  const [comments, setComments] = useState('');
  const [reasons, setReasons] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const location = useLocation();
  const currentUrl = location.pathname;
  const getParams = currentUrl.split('userId=');

  useEffect(() => {
    if (getParams.length > 1 && getParams[1].length === 24) {
      logout();
    }
  }, []);

  const submitData = async comments => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/extension/candidate/feedback`,
        {
          userId: getParams[1],
          type: 'UNINSTALL',
          feedback: comments,
          other_details: {
            questionAndAnswers: [
              {
                question: 'Whay are you leaving?',
                answer: reasons
              }
            ]
          }
        }
      );
      if (response && !response.error) {
        setIsSubmit(true);
        setTimeout(() => {
          window.open(process.env.REACT_APP_NB_WEBSITE_URL, '_self');
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const clearData = () => {
    var items = document.getElementsByName('feedback');
    items.forEach(item => {
      if (item.type == 'checkbox') item.checked = false;
    });
    setReasons([]);
    setComments('');
  };

  const feedbackData = e => {
    if (e.target.checked) {
      if (!reasons.includes(e.target.value)) {
        setReasons([e.target.value, ...reasons]);
      }
    } else {
      setReasons(reasons.filter(reason => reason !== e.target.value));
    }
  };

  const feedbacks = [
    {
      name: 'feedback1',
      label: 'The plugin suddenly stopped working'
    },
    {
      name: 'feedback2',
      label: 'The plugin broke my site'
    },
    {
      name: 'feedback3',
      label: 'I found a better plugin'
    },
    {
      name: 'feedback4',
      label: 'I only needed the plugin for a short period'
    },
    {
      name: 'feedback5',
      label: 'I no longer need the plugin'
    },
    {
      name: 'feedback6',
      label: `Its a temporary deactivation, I'm just dubugging an issue`
    }
  ];

  const FeedbackSuccess = () => {
    return (
      <>
        <div className={styles.backdrop}></div>
        <div className={styles.feedbackSuccessCntr}>
          <img src={thankyou} alt="thankyou" />
          <p className={styles.msg}>Your feedback was successfully submitted</p>
        </div>
      </>
    );
  };

  return (
    <section className={styles.feedback}>
      <img
        src={nurtureBoxLogo}
        alt="nurturebox"
        className={styles.nurtureBoxImg}
      />
      <div className={styles.topSection}>
        <h4 className={styles.title}>We’re sorry to see you go 😔</h4>
        <p className={styles.description}>
          How do you think we can make NurtureBox better?
        </p>
        <p className={styles.description}>We would love to hear from you.</p>
      </div>
      <div className={styles.contentSection}>
        <div className={styles.leftSection}>
          <img src={feedBack} alt="Logo" className={styles.faqImage} />
        </div>
        <div className={styles.inputSection}>
          <h6>Why are you leaving?</h6>
          <form>
            {feedbacks.map(feedback => (
              <>
                <div className={styles.options}>
                  <input
                    type="checkbox"
                    id={feedback.name}
                    name="feedback"
                    value={feedback.label}
                    onClick={feedbackData}
                  />
                  <label> {feedback.label}</label>
                  <br></br>
                </div>
              </>
            ))}
            <h6>What could we do better?</h6>
            <textarea
              row="5"
              col="100"
              onChange={e => setComments(e.target.value)}
              value={comments}
            ></textarea>
          </form>
          {isSubmit && (
            <>
              {ReactDOM.createPortal(
                <FeedbackSuccess />,
                document.getElementsByTagName('body')[0]
              )}
            </>
            // <p className={styles.successMsg}>
            //   Feedback submitted successfully !!!
            // </p>
          )}
          <div className={styles.btnSection}>
            <Button
              label="Clear"
              className={styles.clearBtn}
              clickHandler={() => clearData('')}
            />
            <Button
              label="Submit"
              className={styles.saveBtn}
              clickHandler={() => submitData(comments)}
              disabled={isSubmit || isArrayEmpty(reasons)}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default connect(null, { logout })(Feedback);
