import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_PROCESSING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  MOBILE_VERIFICATION,
  UPDATE_USER,
  UPDATE_EXTENSION_INSTALLED_STATE
} from '../actionTypes/auth';
import { returnErrors } from '../actions/error';
import axios from '../../utils/api';
import { getAuthHeader } from '../../utils/auth';

export const loadUser = () => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });
  axios
    .get('/user', getAuthToken(getState))
    .then(res =>
      dispatch({
        type: USER_LOADED,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: AUTH_ERROR,
        payload: err.message
      })
    );
};

export const login = oAuthCode => async dispatch => {
  dispatch({ type: LOGIN_PROCESSING });
  dispatch(returnErrors({}));
  const body = {
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    authCode: oAuthCode,
    applicationType: 'web'
  };
  try {
    const response = await axios.post(
      process.env.REACT_APP_GOOGLE_AUTH_URL,
      body
    );
    dispatch({ type: LOGIN_SUCCESS, payload: response.data });
    // return { user: response.data, isNewUser: true, error: '' };
    return {
      user: response.data,
      isNewUser: !!response.data?.isNewUser
    };
  } catch (err) {
    let errorMessage = err.message;
    console.log({ err: err });
    if (
      err.response?.data?.message &&
      ['gmail.com', 'user not allowed'].some(msg =>
        err.response.data.message.includes(msg)
      )
    ) {
      errorMessage = 'Please use your work email address';
    }
    dispatch({ type: LOGIN_FAIL, payload: errorMessage });
    dispatch(returnErrors({ message: errorMessage, code: 401 }));
    return { error: errorMessage, user: null };
  }
};

export const logout = () => async dispatch => {
  const { headers } = getAuthHeader();
  try {
    dispatch({ type: LOGOUT_SUCCESS });
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/logout`,
      {},
      {
        headers: { ...headers }
      }
    );
  } catch (err) {
    if (err.response.status === 401) {
      dispatch({ type: LOGOUT_SUCCESS });
    }
  }
};

export const updateUser = data => ({ type: UPDATE_USER, payload: data });

export const getAuthToken = getState => {
  // Get token from localstorage
  // const token = localStorage.getItem('authToken')
  const token = getState().auth.token;
  const config = {};
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
};

export const getMobileVerification = is_phone_verified => ({
  type: MOBILE_VERIFICATION,
  payload: is_phone_verified
});

export const updateExtensionState = () => dispatch => {
  let action = {};
  const cb = state => {
    dispatch({
      type: UPDATE_EXTENSION_INSTALLED_STATE,
      payload: state
    });
  };
  try {
    chrome.runtime.sendMessage(
      process.env.REACT_APP_EXTENSION_ID,
      { message: 'IS_EXTENSION_INSTALLED', token: '' },
      function (reply) {
        if (reply && reply.present) {
          cb(true);
        } else {
          cb(false);
        }
      }
    );
  } catch (err) {
    console.log(err);
  }
  return action;
};
