import React from 'react';
import styles from './styles.module.scss';
import verifiedIcon from '../../assets/images/verifiedIcon.svg';

const ConfirmationCard = () => {
  return (
    <div className={styles.ConfirmationCard}>
      <div className={styles.ConfirmationCardOuter}>
        <div className={styles.cardInner}>
          <img
            className={styles.verifiedTick}
            src={verifiedIcon}
            alt="verified"
          />
          <h4>Account created</h4>
          <p>
            Lets get started. We’re excited to show you how NurtureBox can make
            you day more efficient.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationCard;
