import { SWITCH_TO_PAGE } from '../actionTypes';

const initialState = {
  currentPage: null
};

const screenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_TO_PAGE:
      return {
        ...state,
        currentPage: action.name
      };

    default:
      return state;
  }
};

export default screenReducer;
