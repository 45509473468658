import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

/**
 * Button component with customization
 * @param {String} type - Funtionality type of the button
 * @param {string} variant - Type of button
 * @param {string} label - Text inside the button
 * @param {string} className - Additional classes to override
 * @param {boolean} disabled - To set the button clickable or not
 * @param {Element} startIcon - Image to be rendered before button text
 * @param {Function} clickHandler - Function that handles the click
 * @param {String} disabledStyles - Styles to apply when the button is disabled
 *
 * @author Karthik Raja
 */
const Button = ({
  variant,
  label,
  className,
  type,
  disabled,
  startIcon,
  endIcon,
  clickHandler,
  disabledStyles
}) => (
  <button
    className={`${styles.defaultButton} ${
      variant === 'bordered' && styles.borderedButton
    }
    ${disabled ? disabledStyles || styles.buttonDisabled : ''} 
    ${className}`}
    onClick={clickHandler}
    type={type}
    disabled={disabled}
    tabIndex="0"
  >
    {startIcon}
    {label}
    {endIcon}
  </button>
);

Button.defaultProps = {
  type: 'button',
  variant: 'normal',
  className: '',
  disabled: false,
  startIcon: null,
  disabledStyles: ''
};

Button.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  startIcon: PropTypes.element,
  clickHandler: PropTypes.func.isRequired,
  disabledStyles: PropTypes.string
};

export default Button;
