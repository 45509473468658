export const CREATE_PROSPECT_SUCCESS = 'CREATE_PROSPECT_SUCCESS';
export const CREATE_PROSPECT_FAILED = 'CREATE_PROSPECT_FAILED';
export const CREATE_PROSPECT_LOADING = 'CREATE_PROSPECT_LOADING';
export const GET_PROSPECTS_SUCCESS = 'GET_PROSPECTS_SUCCESS';
export const GET_PROSPECTS_FAILED = 'GET_PROSPECTS_FAILED';
export const GET_PROSPECTS_LOADING = 'GET_PROSPECTS_LOADING';
export const CLEAR_RESPONSE_STATUS = 'CLEAR_RESPONSE_STATUS';
export const DELETE_LIST_PROSPECT_LOADING = 'DELETE_LIST_PROSPECT_LOADING';
export const DELETE_LIST_PROSPECT_SUCCESS = 'DELETE_LIST_PROSPECT_SUCCESS';
export const DELETE_LIST_PROSPECT_FAILED = 'DELETE_LIST_PROSPECT_FAILED';
export const CREATE_PROSPECT_LIST_LOADING = 'CREATE_PROSPECT_LIST_LOADING';
export const CREATE_PROSPECT_LIST_SUCCESS = 'CREATE_PROSPECT_LIST_SUCCESS';
export const CREATE_PROSPECT_LIST_FAILED = 'CREATE_PROSPECT_LIST_FAILED';
export const CREATE_PROSPECT_SEQUENCE_LOADING =
  'CREATE_PROSPECT_SEQUENCE_LOADING';
export const CREATE_PROSPECT_SEQUENCE_SUCCESS =
  'CREATE_PROSPECT_SEQUENCE_SUCCESS';
export const CREATE_PROSPECT_SEQUENCE_FAILED =
  'CREATE_PROSPECT_SEQUENCE_FAILED';
