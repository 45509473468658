import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import classNames from 'classnames/bind';
import ErrorMsg from '../errorMessage';
import styles from './styles.module.scss';

/**
 * A reusable text input component
 * @param {Object} field - Field object containing all the required props for the input
 * @param {Object} form - The wrapper form
 * @param {String} type - Type of input (text or email)
 * @param {String} placeholder - Placeholder text
 * @param {String} label - Label for the input
 * @param {Boolean} disabled - To disable/enable input
 * @param {String} className - Additional overridding classes
 * @param {String} optionalLabel - Label to be shown on top right of the input field
 * @param {Boolean} errorOnTouch - Enable/disable error on field focus. By default error will be shown on blur
 * @param {Object || String} startAdornment - Start adornment HTML element or string
 * @param {Object || String} endAdornment - End adornment HTML element or string
 * @param {Function} customHandler - Custom onChange handler
 * @param {Boolean} readOnly - Set input field with read/write capability
 */
const TextInput = ({
  field: { name, onChange, ...rest },
  type,
  variant,
  placeholder,
  label,
  disabled,
  className,
  optionalLabel,
  startAdornment,
  endAdornment,
  customHandler,
  readOnly,
  maxLength
}) => {
  const cssStyles = classNames.bind(styles);
  const inputClass = cssStyles({
    textInput: true,
    endAdornmentPadding: endAdornment && !startAdornment,
    startAdornmentPadding: startAdornment && !endAdornment,
    bothAdornmentPadding: startAdornment && endAdornment
  });

  const adornment = cssStyles({
    adornment: true,
    right: endAdornment && !startAdornment,
    left: startAdornment && !endAdornment,
    both: startAdornment && endAdornment
  });

  const onChangeHandler = event => {
    onChange(event);
    customHandler && customHandler(event.target.value);
  };

  return (
    <div className={`${styles.textField} ${className}`}>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
          {optionalLabel && (
            <aside className={styles.optionalLabel}>{optionalLabel}</aside>
          )}
        </label>
      )}
      {startAdornment && (
        <aside className={`${adornment} ${label ? styles.withLabel : ''}`}>
          {startAdornment}
        </aside>
      )}

      <input
        type={type}
        name={name}
        id={name}
        {...rest}
        placeholder={placeholder}
        className={`${inputClass} ${
          variant === 'filled' && styles.filledTextInput
        }`}
        onChange={onChangeHandler}
        disabled={disabled}
        readOnly={readOnly}
        maxLength={maxLength}
      />
      {endAdornment && (
        <aside className={`${adornment} ${label ? styles.withLabel : ''}`}>
          {endAdornment}
        </aside>
      )}
      <div className={styles.errorContainer}>
        <ErrorMessage name={name} component={ErrorMsg} />
      </div>
    </div>
  );
};

TextInput.defaultProps = {
  type: 'text',
  label: '',
  variant: 'normal',
  placeholder: '',
  className: '',
  optionalLabel: '',
  errorOnTouch: true,
  startAdornment: '',
  endAdornment: '',
  readOnly: false,
  disabled: false
};

TextInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  optionalLabel: PropTypes.string,
  errorOnTouch: PropTypes.bool,
  className: PropTypes.string,
  startAdornment: PropTypes.string,
  endAdornment: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};
export default TextInput;
