import React from 'react';
import styles from './styles.module.scss';
import faceBookLogo from '../../assets/images/facebook.svg';
import medtronicLogo from '../../assets/images/medtronic.svg';
import microsoftLogo from '../../assets/images/microsoft.svg';
import ebayLogo from '../../assets/images/ebay.svg';
import zyngraLogo from '../../assets/images/zyngra.svg';
import siemensLogo from '../../assets/images/siemens.svg';
import socialIcons from '../../assets/images/social-info.png';
import socialIcon from '../../assets/images/mail-campaign-1.svg';
import mailCampaign from '../../assets/images/mail-campaign.svg';
import whatsAppUsageLogo from '../../assets/images/whatsapp-usage.svg';
import lemlistCampaign from '../../assets/images/lemlist-campaign.svg';

const InstructionScreen = ({ formStep }) => {
  const data = {
    1: {
      contentImage: socialIcons,
      contentDescription:
        'Find prospect contact info and social media in one click'
    },
    2: {
      contentImage: mailCampaign,
      contentLogo: socialIcon,
      contentDescription: 'Send email, campaign messages faster than ever'
    },
    3: {
      contentImage: whatsAppUsageLogo,
      contentLogo: lemlistCampaign,
      contentDescription: 'Multichannel outreach to engage with prospects'
    }
  };
  return (
    <>
      <div className={styles.rightSection}>
        <h5 className={styles.title}>Why NurtureBox?</h5>
        <p className={styles.description}>
          Get organized. Stay focused. Fly through recruitment.
        </p>
        <div className={styles.dataSection}>
          <div
            className={`${styles.image} ${formStep == 1 ? styles.flexEnd : ''}`}
          >
            <img
              src={data[formStep].contentImage}
              className={formStep !== 1 ? styles.adjustHeight : ''}
              alt="Logo"
            />
            {formStep !== 1 ? (
              <img
                src={data[formStep].contentLogo}
                className={
                  formStep === 2 ? styles.subImage : styles.lemlistImage
                }
                alt="logo"
              />
            ) : (
              ''
            )}
          </div>
          <div
            className={`${styles.imageDescription} ${
              formStep == 1 ? styles.textAlign : ''
            }`}
          >
            <h4 className={styles.content}>
              {data[formStep].contentDescription}
            </h4>
          </div>
        </div>
        <div className={styles.footerSection}>
          <h4 className={styles.footerTitle}>You&apos;re in good company</h4>
          <p className={styles.footerDescription}>
            Fill your candidate pipeline in no time. Candidates recruited with
            NurtureBox work at
          </p>
          <div className={styles.companyImages}>
            <img src={faceBookLogo} alt="Logo" />
            <img src={medtronicLogo} alt="Logo" />
            <img src={microsoftLogo} alt="Logo" />
            <img src={ebayLogo} alt="Logo" />
            <img src={zyngraLogo} alt="Logo" />
            <img src={siemensLogo} alt="Logo" />
          </div>
        </div>
      </div>
    </>
  );
};

export default InstructionScreen;
