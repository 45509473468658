import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Timer = ({ time, timerCompleted }) => {
  const [seconds, setSeconds] = useState(time);
  useEffect(() => {
    if (seconds > 0) {
      const timeModified = String((seconds - 1) % 60).padStart(2, '0');
      setTimeout(() => setSeconds(timeModified), 1000);
    } else {
      timerCompleted();
    }
  }, [seconds]);

  return (
    <div>
      &nbsp;Resend OTP in <span className={styles.otpTimer}>00:{seconds}</span>
    </div>
  );
};

Timer.propTypes = {
  time: PropTypes.number.isRequired,
  timerCompleted: PropTypes.func.isRequired
};

export default Timer;
