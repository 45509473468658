export const GET_LISTS_SUCCESS = 'GET_LISTS_SUCCESS';
export const GET_LISTS_FAILED = 'GET_LISTS_FAILED';
export const GET_LISTS_LOADING = 'GET_LISTS_LOADING';
export const CREATE_LIST_SUCCESS = 'CREATE_LIST_SUCCESS';
export const CREATE_LIST_FAILED = 'CREATE_LIST_FAILED';
export const CREATE_LIST_LOADING = 'CREATE_LIST_LOADING';
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS';
export const DELETE_LIST_FAILED = 'DELETE_LIST_FAILED';
export const DELETE_LIST_LOADING = 'DELETE_LIST_LOADING';
export const GET_DROPDOWN_LIST_SUCCESS = 'GET_DROPDOWN_LIST_SUCCESS';
export const GET_DROPDOWN_LIST_FAILED = 'GET_DROPDOWN_LIST_FAILED';
export const GET_DROPDOWN_LIST_LOADING = 'GET_DROPDOWN_LIST_LOADING';
export const CLEAR_RESPONSE_STATUS = 'CLEAR_RESPONSE_STATUS';
export const GET_LISTS_BY_NAME = 'GET_LISTS_BY_NAME';
