export const GET_SEQUENCES_SUCCESS = 'GET_SEQUENCES_SUCCESS';
export const GET_SEQUENCES_FAILED = 'GET_SEQUENCES_FAILED';
export const GET_SEQUENCES_LOADING = 'GET_SEQUENCES_LOADING';
export const CREATE_SEQUENCES_SUCCESS = 'CREATE_SEQUENCES_SUCCESS';
export const CREATE_SEQUENCES_FAILED = 'CREATE_SEQUENCES_FAILED';
export const CREATE_SEQUENCES_LOADING = 'CREATE_SEQUENCES_LOADING';
export const DELETE_SEQUENCES_SUCCESS = 'DELETE_SEQUENCES_SUCCESS';
export const DELETE_SEQUENCES_FAILED = 'DELETE_SEQUENCES_FAILED';
export const DELETE_SEQUENCES_LOADING = 'DELETE_SEQUENCES_LOADING';
export const GET_DROPDOWN_SEQUENCES_SUCCESS = 'GET_DROPDOWN_SEQUENCES_SUCCESS';
export const GET_DROPDOWN_SEQUENCES_FAILED = 'GET_DROPDOWN_SEQUENCES_FAILED';
export const GET_DROPDOWN_SEQUENCES_LOADING = 'GET_DROPDOWN_SEQUENCES_LOADING';
export const CLEAR_RESPONSE_STATUS = 'CLEAR_RESPONSE_STATUS';
export const GET_SEQUENCES_BY_NAME = 'GET_SEQUENCES_BY_NAME';
export const SET_TEMPORARY_SEQUENCE_TITLE = 'SET_TEMPORARY_SEQUENCE_TITLE';
export const CLEAR_TEMPORARY_SEQUENCE_TITLE = 'CLEAR_TEMPORARY_SEQUENCE_TITLE';
export const CLEAR_SEQUENCE_RESPONSE_STATUS = 'CLEAR_SEQUENCE_RESPONSE_STATUS';
export const CREATE_PROSPECT_SEQUENCE_SUCCESS =
  'CREATE_PROSPECT_SEQUENCE_SUCCESS';
export const CREATE_PROSPECT_SEQUENCE_ERROR = 'CREATE_PROSPECT_SEQUENCE_ERROR';
