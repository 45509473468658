import React from 'react';
import styles from './styles.module.scss';
import errorIcon from '../../assets/images/errorIcon.svg';
import Button from '../../components/button';
import { logout } from '../../redux/actions';
import { connect } from 'react-redux';

const errorMessages = {
  500: `We’re not able to create your account. Please try again after
  sometime`,
  404: `The OTP has been expired. Please try again`,
  409: 'The mobile number is already linked to an account. Please use a different number'
};
const ErrorCard = ({ errorCode = 500, setErrorCode, logout }) => {
  const reload = () => {
    logout();
    setErrorCode('');
    window.location.reload();
  };

  return (
    <div className={styles.ConfirmationCard}>
      <div className={styles.ConfirmationCardOuter}>
        <div className={styles.cardInner}>
          <img className={styles.verifiedTick} src={errorIcon} alt="error" />
          <h4>Failed to create account</h4>
          <p>{errorMessages[errorCode]}</p>
          <div className={styles.buttonRow}>
            <Button
              label="Close"
              className={styles.closeBtn}
              clickHandler={reload}
            />
            <Button
              label="Try again"
              className={styles.tryAgainBtn}
              clickHandler={reload}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { logout })(ErrorCard);
