import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from './styles.module.scss';

/**
 * Loader custom component
 * @param {Number} progressValue - based on this value progress bar will move
 * @param {string} className - Additional class to override the existing styles
 * @param {string} loaderMsg -  wherever need messages to show
 * @author Balasubramanian
 */
const LoaderComponent = ({ size, progressValue, loaderMsg, className }) => {
  return (
    <section
      className={`${
        progressValue ? styles.loaderContainer : styles.spinnerContainer
      } ${className}`}
    >
      {progressValue && <span className={styles.closeButton}>×</span>}
      <div className={styles.bodyContent}>
        <CircularProgressbar
          className={`${styles.progressBar} ${styles[size]}`}
          value={progressValue ? progressValue : 65}
          counterClockwise
          strokeWidth={6}
          styles={buildStyles({
            pathTransitionDuration: 0.15,
            pathColor: '#2934D0'
          })}
        />
        {progressValue && <p className={styles.loaderContent}>{loaderMsg}</p>}
      </div>
    </section>
  );
};

LoaderComponent.defaultProps = {
  progressValue: null,
  loaderMsg: '',
  className: ''
};

LoaderComponent.propTypes = {
  progressValue: PropTypes.number,
  className: PropTypes.string,
  loaderMsg: PropTypes.string
};

export default LoaderComponent;
