import React, { useEffect } from 'react';
import { useField, ErrorMessage } from 'formik';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import ErrorMsg from '../errorMessage';
import arrow from '../../../assets/images/arrow.svg';
import styles from './styles.module.scss';

/**
 * A reusable select box component
 * @param {Object} field - Field object containing all the required props for the select box
 * @param {Object} form - The wrapper form
 * @param {String} placeholder - Placeholder text
 * @param {String} label - Label for the select box
 * @param {Boolean} disabled - To disable/enable select box
 * @param {String} className - Additional overridding classes
 * @param {String} optionalLabel - Label to be shown on top right of the select box field
 * @param {Boolean} errorOnTouch - Enable/disable error on field focus. By default error will be shown on blur
 * @param {Object || String} startAdornment - Start adornment HTML element or string
 * @param {Object || String} endAdornment - End adornment HTML element or string
 * @param {Object} options - list of options
 * @param {String} id - ID of the select box
 * @param {String} valueKey - The key in the option object that is to be set as the value
 * @param {Boolean} isMulti - Flag for single/multiselect.
 * @param {Function} customHandler - Custom on change handler called along with the default one, if given
 */

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={arrow} alt="carat" />
    </components.DropdownIndicator>
  );
};

const CustomSelect = ({
  field: { name },
  placeholder,
  label,
  variant,
  disabled,
  className,
  optionalLabel,
  options,
  id,
  valueKey,
  customHandler,
  isMulti,
  defaultValue = '',
  value = ''
}) => {
  const [, , helpers] = useField(name);
  const { setValue } = helpers;

  const customStyles = {
    indicatorsContainer: () => ({ border: 'none' }),
    control: provided => ({
      ...provided,
      backgroundColor: 'transparent',
      borderRadius: '4px',
      minHeight: '34px',
      height: '50px',
      border: '1px solid #d9dbe9'
    }),
    valueContainer: provided => ({
      ...provided,
      marginLeft: '6px',
      padding: '2px 2px',
      height: '53px'
    }),
    input: provided => ({
      ...provided,
      height: '50px'
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: '13px',
      display: 'block',
      color: '#6E7191'
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: '#ffff',
      borderRadius: '4px',
      boxShadow: 'none',
      border: 'none'
    }),
    menuList: provided => ({
      ...provided,
      boxShadow: '0px 1px 16px rgba(0, 0, 0, 0.12)',
      backgroundColor: '#ffff',
      overflow: 'auto',
      maxHeight: '200px'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#2A3445' : '#4C5A73',
      fontFamily: `"Inter-medium", sans-serif`,
      backgroundColor: state.isFocused ? '#F5F5F8' : 'transparent',
      padding: '10px 0 15px 22px',
      fontSize: '14px',
      fontWeight: state.isFocused ? '500' : 'normal',
      cursor: 'pointer'
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: '14px',
      fontFamily: `"Inter-medium", sans-serif`,
      textTransform: 'capitalize'
    })
  };

  const onChangeHandler = option => {
    setValue(option[valueKey] || option);
    customHandler && customHandler(option[valueKey] || option);
  };

  // Set default value to the filed if provided
  useEffect(() => {
    if (!defaultValue) return;
    onChangeHandler(defaultValue);
  }, []);
  return (
    <div className={`${styles.selectBox} ${className}`}>
      {label && (
        <label htmlFor={id || name} className={styles.label}>
          {label}{' '}
          {optionalLabel && (
            <aside className={styles.optionalLabel}>{optionalLabel}</aside>
          )}
        </label>
      )}
      <Select
        options={options}
        name={name}
        onChange={onChangeHandler}
        styles={customStyles}
        components={{ DropdownIndicator }}
        placeholder={placeholder}
        isDisabled={disabled}
        id={id || name}
        controlShouldRenderValue={!isMulti}
        className={styles[variant]}
        defaultValue={defaultValue}
        value={value}
      />
      <ErrorMessage name={name} component={ErrorMsg} />
    </div>
  );
};

CustomSelect.defaultProps = {
  type: 'text',
  label: '',
  placeholder: '',
  className: '',
  optionalLabel: '',
  errorOnTouch: true,
  startAdornment: '',
  endAdornment: '',
  disabled: false,
  options: null,
  id: '',
  valueKey: '',
  isMulti: false,
  variant: 'bordered'
};

CustomSelect.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  optionalLabel: PropTypes.string,
  errorOnTouch: PropTypes.bool,
  className: PropTypes.string,
  startAdornment: PropTypes.string,
  endAdornment: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  id: PropTypes.string,
  valueKey: PropTypes.string,
  customHandler: PropTypes.func,
  isMulti: PropTypes.bool,
  variant: PropTypes.string.isRequired
};

export default CustomSelect;
