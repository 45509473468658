import React, { useEffect, useState } from 'react';
import Button from '../../components/button';
import styles from './styles.module.scss';
import linkedInImage from '../../assets/images/linkedin-extension.png';
import { connect } from 'react-redux';
import { redirectToLinkedIn } from '../../utils/common';
import { logout } from '../../redux/actions';
import Spinner from '../../components/Spinner';

const LinkedinExtension = ({ logout, showTour, tourLink }) => {
  const [manualRedirection, setManualRedirection] = useState(false);

  useEffect(() => {
    setTimeout(() => setManualRedirection(true), 6000);
  }, []);
  useEffect(() => {
    if (showTour && tourLink)
      setTimeout(() => {
        window.open(tourLink, '_self');
        console.log('redirection...');
      }, 3000);
  }, [showTour, tourLink]);

  return (
    <section className={styles.page}>
      <div className={styles.linkedInBox}>
        <div className={styles.linkedInboxInner}>
          <div>
            <img
              src={linkedInImage}
              alt="Logo"
              className={styles.linkedInImage}
            />
            <p className={styles.mainText}>
              That’s perfect! Thank <span onClick={logout}>you</span> 🙏🏽
            </p>
            <p className={styles.miniText}>
              NurtureBox will save you hours per week in recruitment
            </p>
          </div>
          <div className={styles.linkedInButton}>
            {showTour && !manualRedirection ? (
              <div className={styles.redirection}>
                <Spinner />
                <p>Redirecting you to Linkedin...</p>
              </div>
            ) : (
              <div className={styles.redirection}>
                <Button
                  label="Start sourcing"
                  className={styles.linkedIn}
                  clickHandler={() => redirectToLinkedIn(false)}
                />
                {/* {showTour && manualRedirection && <p>Else, click the button</p>} */}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
const mapStateToProps = state => ({
  token: state.auth.token
});

export default connect(mapStateToProps, { logout })(LinkedinExtension);
