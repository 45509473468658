import React from 'react';
import 'react-phone-number-input/style.css';
import PropTypes from 'prop-types';
import { useState } from 'react';
import UserDetails from './userDetails';
import OTPVerification from './otpVerification';
import ConfirmationCard from './confirmationCard';
import { loadUser } from '../../redux/actions';
import { connect } from 'react-redux';
import ErrorCard from './errorCard';
import CompanyDetails from './companyDetails';
import InstructionScreen from './instructionScreen';
import styles from './styles.module.scss';

const PostLogin = ({ username, first_name, last_name }) => {
  const domainName = username
    .substring(username.lastIndexOf('@') + 1)
    .split('.com');
  let company = '';
  const ignoreCompanyName = ['gmail', 'hotmail', 'yahoo'];
  if (!ignoreCompanyName.includes(domainName[0])) {
    company = domainName[0];
  }

  const [formStep, setFormStep] = useState(1);
  const [userInfo, setUSerInfo] = useState({
    phone_number: '',
    companyName: company,
    first_name: first_name,
    last_name: last_name
  });
  const [errorcode, setErrorCode] = useState('');
  const nextStep = (step = null) => {
    setFormStep(step || formStep + 1);
  };

  const handleChange = (details = {}) => {
    setUSerInfo({ ...userInfo, ...details });
  };

  const handleBack = () => {
    setFormStep(1);
    // TODO remove country code on edit
  };
  return !errorcode ? (
    <>
      <section className={styles.formSection}>
        {formStep == 1 ? (
          <UserDetails
            nextStep={nextStep}
            handleChange={handleChange}
            values={userInfo}
            setErrorCode={setErrorCode}
          />
        ) : formStep == 99 ? (
          <OTPVerification
            nextStep={nextStep}
            phone_number={userInfo.phone_number}
            setErrorCode={setErrorCode}
            handleBack={handleBack}
          />
        ) : formStep == 2 ? (
          <CompanyDetails
            nextStep={nextStep}
            handleChange={handleChange}
            companyName={company}
            setErrorCode={setErrorCode}
            handleBack={handleBack}
          />
        ) : formStep == 3 ? (
          <ConfirmationCard />
        ) : null}
        {formStep !== 3 ? <InstructionScreen formStep={formStep} /> : null}
      </section>
    </>
  ) : (
    <ErrorCard errorCode={errorcode} setErrorCode={setErrorCode} />
  );
};

const mapStateToProps = state => {
  return {
    username: state.auth.username,
    first_name: state.auth.first_name,
    last_name: state.auth.last_name
  };
};

PostLogin.propTypes = {
  username: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string
};

export default connect(mapStateToProps, { loadUser })(PostLogin);
