import React, { createRef, useState } from 'react';
import { useField, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

import ErrorMsg from '../errorMessage';
import Button from '../../button';
import styles from './styles.module.scss';

const fileTypes = {
  image: 'image/*',
  document: '.pdf, .doc',
  any: '/*'
};

/**
 * A file input component that can accept any file type, read the contents
 * and send the result through a callback. Default accept and read types
 * are image and dataURL respectively.
 * @param {Object} field - The object containing all the basic attributes for the input field
 * @param {Object} form - The enclosing form object
 * @param {String} acceptType - The acceptable type of file
 * @param {Function} filePreview - Callback that receives the content of uploaded file
 * @param {Boolean} showField - Flag to show or hide the field that has the file name
 * @param {String} label - Label for the field
 * @param {String} optionalLabel - Optional label above the field in right side
 * @param {String} placeholder - Placeholder text in the input field
 * @param {String} id - ID for the input element
 * @param {String} buttonVariant - Type of upload button. Default is block
 * @param {String} buttonStyles - Additional styles for the button
 *
 * @author Karthik
 */
const FileInput = ({
  field: { name },
  acceptType,
  filePreview,
  showField,
  label,
  optionalLabel,
  placeholder,
  id,
  buttonVariant,
  buttonStyles
}) => {
  const inputRef = createRef();
  const [, , helpers] = useField(name);
  const { setValue } = helpers;
  const [filePath, setFilePath] = useState(placeholder);

  const handleUpload = event => {
    const file = event.currentTarget.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setValue({ source: fileReader.result, name: file.name });
      setFilePath(file.name);
      filePreview(fileReader.result);
    };
    switch (acceptType) {
      case 'image': {
        fileReader.readAsDataURL(file);
        break;
      }
      case 'file': {
        fileReader.readAsText(file, 'UTF-8');
        break;
      }
      default:
        fileReader.readAsDataURL(file);
    }
  };

  return (
    <section className={styles.fileUploadBox}>
      {label && (
        <label htmlFor={id || name} className={styles.label}>
          {label}{' '}
          {optionalLabel && (
            <aside className={styles.optionalLabel}>{optionalLabel}</aside>
          )}
        </label>
      )}
      <input
        type="file"
        name={name}
        accept={fileTypes[acceptType]}
        id={id || name}
        ref={inputRef}
        className={styles.input}
        onChange={handleUpload}
      />
      <div className={styles.uploadSection}>
        {showField && (
          <input
            type="text"
            value={filePath}
            className={styles.filePathContainer}
          />
        )}
        <Button
          label="Upload"
          clickHandler={() => inputRef.current.click()}
          className={`${styles.uploadButton} ${
            !showField ? styles.fullWidth : ''
          } ${buttonStyles}`}
          variant={buttonVariant}
        />
      </div>
      <ErrorMessage name={name} component={ErrorMsg} />
    </section>
  );
};

FileInput.defaultProps = {
  acceptType: 'image',
  showField: true,
  label: '',
  optionalLabel: '',
  placeholder: 'Upload a file',
  buttonVariant: 'bordered',
  buttonStyles: ''
};

FileInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  acceptType: PropTypes.string,
  filePreview: PropTypes.func.isRequired,
  showField: PropTypes.bool,
  label: PropTypes.string,
  optionalLabel: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  buttonVariant: PropTypes.string,
  buttonStyles: PropTypes.string
};

export default FileInput;
