export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_PROCESSING = 'LOGIN_PROCESSING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const MOBILE_VERIFICATION = 'MOBILE_VERIFICATION';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_EXTENSION_INSTALLED_STATE =
  'UPDATE_EXTENSION_INSTALLED_STATE';
