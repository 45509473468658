import {
  SET_PROSPECT_FORM_VALUES,
  CLEAR_PROSPECT_FORM_VALUES
} from '../actionTypes/prospectForm';

const initialState = {
  initialFormValues: {
    firstName: '',
    lastName: '',
    email: '',
    jobRole: '',
    company: '',
    companyLocation: '',
    file: null,
    gender: '',
    listIds: [],
    sequenceIds: []
  }
};

const prospectFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROSPECT_FORM_VALUES: {
      return {
        ...state,
        initialFormValues: action.payload
      };
    }
    case CLEAR_PROSPECT_FORM_VALUES: {
      return {
        ...state,
        initialFormValues: {
          firstName: '',
          lastName: '',
          email: '',
          jobRole: '',
          company: '',
          companyLocation: '',
          file: null,
          gender: '',
          listIds: [],
          sequenceIds: []
        }
      };
    }

    default:
      return state;
  }
};

export default prospectFormReducer;
