import { GET_ERRORS, CLEAR_ERRORS } from '../actionTypes/error';

export const returnErrors = error => {
  return {
    type: GET_ERRORS,
    payload: error
  };
};

// CLEAR ERRORS
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
