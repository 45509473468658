import {
  USER_LOADING,
  USER_LOADED,
  LOGIN_PROCESSING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  MOBILE_VERIFICATION,
  UPDATE_USER,
  UPDATE_EXTENSION_INSTALLED_STATE
} from '../actionTypes/auth';

const parsePhoneNumberStatusToBoolean = () => {
  const is_phone_verified = localStorage.getItem('mobileVerification');
  if (typeof is_phone_verified === 'string') {
    if (is_phone_verified === 'true') return true;
    return false;
  }
  if (typeof is_phone_verified === 'undefined') return false;
};

const initialState = {
  token: localStorage.getItem('authToken'),
  username: localStorage.getItem('currentUser'),
  is_phone_verified: parsePhoneNumberStatusToBoolean(),
  first_name: localStorage.getItem('firstName'),
  last_name: localStorage.getItem('lastName'),
  tour_steps_completed: localStorage.getItem('tourStep'),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  isExtensionInstalled: false
};
/* eslint-disable indent */
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADING: {
      return {
        ...state,
        isLoading: true
      };
    }
    case UPDATE_USER:
    case USER_LOADED: {
      const { first_name, last_name, ...rest } = action.payload;
      localStorage.setItem('firstName', first_name);
      localStorage.setItem('lastName', last_name);
      return {
        ...state,
        first_name,
        last_name,
        isAuthenticated: true,
        isLoading: false,
        user: {
          ...rest,
          first_name,
          last_name
        }
      };
    }
    case LOGIN_PROCESSING: {
      return {
        ...state,
        isLoading: true
      };
    }
    case LOGIN_SUCCESS: {
      const {
        access_token,
        username,
        is_phone_verified,
        first_name,
        last_name,
        tour,
        ...rest
      } = action.payload;
      const tourStep = tour?.tour_steps_completed || 0;
      localStorage.setItem('authToken', access_token);
      localStorage.setItem('currentUser', username);
      localStorage.setItem('mobileVerification', is_phone_verified || false);
      localStorage.setItem('firstName', first_name);
      localStorage.setItem('lastName', last_name);
      localStorage.setItem('tourStep', tourStep);
      return {
        ...state,
        token: access_token,
        username,
        is_phone_verified,
        first_name,
        last_name,
        tour_steps_completed: tourStep,
        user: { ...rest, first_name, last_name, username, tour },
        isAuthenticated: true,
        isLoading: false
      };
    }

    case LOGIN_FAIL: {
      localStorage.removeItem('authToken');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('mobileVerification');
      localStorage.removeItem('firstName');
      localStorage.removeItem('lastName');
      localStorage.removeItem('tourStep');
      return {
        ...state,
        token: null,
        user: null,
        username: null,
        is_phone_verified: null,
        first_name: null,
        last_name: null,
        tour_steps_completed: null,
        isAuthenticated: false,
        isLoading: false
      };
    }

    case LOGOUT_SUCCESS: {
      localStorage.removeItem('authToken');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('mobileVerification');
      localStorage.removeItem('firstName');
      localStorage.removeItem('lastName');
      localStorage.removeItem('tourStep');
      return {
        token: null,
        username: null,
        is_phone_verified: false,
        tour_steps_completed: null,
        isAuthenticated: null,
        isLoading: false,
        user: null,
        isExtensionInstalled: false
      };
    }
    case MOBILE_VERIFICATION: {
      localStorage.setItem('mobileVerification', action.payload);
      return {
        ...state,
        is_phone_verified: action.payload,
        isAuthenticated: true,
        isLoading: false
      };
    }

    case UPDATE_EXTENSION_INSTALLED_STATE: {
      return {
        ...state,
        isExtensionInstalled: action.payload
      };
    }

    default:
      return state;
  }
};
/* eslint-enable indent */
export default authReducer;
