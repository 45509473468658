import moment from 'moment';
/**
 * Checks if an array is empty or not
 * @param {Array} arr - The array to check
 */
export function isArrayEmpty(arr) {
  return !(arr && arr.length);
}

/* Trims a text to the specified length and adds three dots at the end, only if the
 * text length is more than the specified length
 */
export function convertToEllipsis(text, trimLength) {
  if (text.length > trimLength) return `${text.slice(0, trimLength)}...`;
  return text;
}

export function convertToSimpleDate(dateTime) {
  const localDate = new Date(dateTime).toString();
  const minutes = moment().diff(localDate, 'm'); //get minutes
  if (minutes <= 1440) {
    return moment(localDate).fromNow();
  } else if (minutes > 1440 && minutes <= 2880) {
    return 'Yesterday';
  } else {
    return moment(localDate).format('DD MMMM YYYY');
  }
}

export function convertValueToTime(value) {
  const hr = Math.floor(value);
  const min = value % 1;
  if (value < 12) {
    return `${hr}${min ? '.30' : ''} am`;
  }
  return `${hr % 12 !== 0 ? hr - 12 : '12'}${min ? '.30' : ''} ${
    value < 24 ? 'pm' : 'am'
  }`;
}

export function redirectToStore() {
  window.open(process.env.REACT_APP_EXTENSION_CHROMESTORE_URL, '_blank');
}

export function redirectToLinkedIn(newTab = false) {
  newTab
    ? window.open('https://www.linkedin.com/', '_blank')
    : window.open('https://www.linkedin.com/', '_self');
}

export function checkExtension(token) {
  try {
    chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, {
      message: 'IS_EXTENSION_INSTALLED',
      token: token
    });
  } catch (err) {
    // TODO
  }
}

export function getTimeDifference(newTime) {
  const present = moment(new Date());
  const past = moment(newTime);
  return present.diff(past, 'minutes');
}
