export const ADD_PROSPECT_SUCCESS_MESSAGE = (name, list) => {
  if (name && list) {
    return `${name} has been successfully added to the ${list} list. Would you like to add another prospect?`;
  }
  return `${name} has been successfully added. Would you like to add another prospect?`;
};
export const ADD_SEQUENCE_SUCCESS_MESSAGE = (name, prospectName) => {
  if (name && prospectName) {
    return `${prospectName} has been successfully added to the ${name} sequence. Would you like to add another prospect?`;
  }
  return `${name} has been added successfully`;
};
export const CREATE_SEQUENCE_ERROR_MESSAGE = {
  400: 'We were unable to add the sequence. Please try again',
  404: 'We were unable to add the sequence. Please try again',
  409: 'Given sequence name already exists',
  500: 'We were unable to add the sequence. Please try again'
};
export const ADD_PROSPECT_ERROR_MESSAGE = {
  400: 'We were unable to add the prospect. Please try again',
  404: 'We were unable to add the prospect. Please try again',
  409: 'Given email already exists',
  500: 'We were unable to add the prospect. Please try again'
};
export const CREATE_LIST_SUCCESS_MESSAGE = 'List created successfully';
export const CREATE_LIST_ERROR_MESSAGE = {
  400: 'We were unable to create the list. Please try again',
  404: 'We were unable to create the list. Please try again',
  409: 'Name already exists',
  500: 'We were unable to create the list. Please try again'
};
export const DELETE_LIST_SUCCESS_MESSAGE =
  'Selected list(s) successfully deleted';
export const DELETE_LIST_ERROR_MESSAGE = {
  400: 'Please select the list(s) that you want to delete.',
  404: 'We were unable to delete the list(s). Please try again',
  500: 'We were unable to delete the list(s). Please try again'
};
export const DELETE_PROSPECT_SUCCESS_MESSAGE =
  'Selected prospect(s) successfully deleted';
export const DELETE_PROSPECT_ERROR_MESSAGE = {
  400: 'Please select the prospect(s) that you want to delete.',
  404: 'We were unable to delete prospect(s) to the list. Please try again',
  500: 'We were unable to delete prospect(s) to the list. Please try again'
};
export const DELETE_SEQUENCE_SUCCESS_MESSAGE =
  'Selected sequence(s) successfully deleted';
export const DELETE_SEQUENCE_ERROR_MESSAGE = {
  400: 'Please select the sequence(s) that you want to delete.',
  404: 'We were unable to delete the selected sequence(s). Please try again',
  500: 'We were unable to delete the selected sequence(s). Please try again'
};
export const UPDATE_PROSPECT_SUCCESS_MESSAGE = 'Prospect successfully updated';
export const UPDATE_PROSPECT_ERROR_MESSAGE = {
  400: 'We were unable to update the prospect. Please try again',
  404: 'We were unable to update the prospect. Please try again',
  409: 'Given email already exists',
  500: 'We were unable to update the prospect. Please try again'
};
export const CREATE_PROSPECT_LIST_SUCCESS_MESSAGE =
  'Prospect(s) have been successfully added';
export const CREATE_PROSPECT_LIST_ERROR_MESSAGE = {
  400: 'We were unable to add prospect(s) to the selected list. Please try again',
  404: 'We were unable to add prospect(s) to the selected list. Please try again',
  500: 'We were unable to add prospect(s) to the selected list. Please try again'
};
export const CREATE_PROSPECT_SEQUENCE_SUCCESS_MESSAGE =
  'Prospect(s) have been successfully added';
export const CREATE_PROSPECT_SEQUENCE_ERROR_MESSAGE = {
  400: 'We were unable to add prospect(s) to the selected sequence. Please try again',
  404: 'We were unable to add prospect(s) to the selected sequence. Please try again',
  500: 'We were unable to add prospect(s) to the selected sequence. Please try again'
};

export const COUNTRYCODE = 'https://ipapi.co/json/';
export const SEARCH_DEBOUNCE_TIME = 500;
export const LISTS_PER_PAGE = 10;
export const PROSPECTS_PER_PAGE = 10;
export const SEQUENCES_PER_PAGE = 10;
export const GENDER_DATA = [
  { name: 'male', label: 'Male' },
  { name: 'female', label: 'Female' }
];
