import { combineReducers } from 'redux';
import screenReducer from './screen';
import authReducer from './auth';
import errorReducer from './error';
import prospectsPageReducer from './prospectsPage';
import listPageReducer from './listPage';
import sequencePageReducer from './sequence';
import prospectFormReducer from './prospectForm';

export default combineReducers({
  screen: screenReducer,
  auth: authReducer,
  error: errorReducer,
  prospects: prospectsPageReducer,
  lists: listPageReducer,
  sequences: sequencePageReducer,
  prospectForm: prospectFormReducer
});
