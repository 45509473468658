import { GET_ERRORS, CLEAR_ERRORS } from '../actionTypes/error';
const initialState = {
  message: null,
  code: null
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ERRORS: {
      const { message, code } = action.payload;
      return {
        message,
        code
      };
    }

    case CLEAR_ERRORS: {
      return {
        message: null,
        code: null
      };
    }

    default: {
      return state;
    }
  }
};

export default errorReducer;
