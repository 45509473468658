import React, { useState } from 'react';
import styles from './styles.module.scss';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikController from '../../components/inputFields/formikController';
import PhoneInput from 'react-phone-number-input';
import Button from '../../components/button';
import { getAuthHeader } from '../../utils/auth';
import axios from '../../utils/api';
import { getMobileVerification } from '../../redux/actions';
import PropTypes from 'prop-types';
import Timer from './timer';
import nurtureBoxLogo from '../../assets/images/nurturebox-full.svg';

const errorMessages = {
  400: `Failed to verify. OTP code you entered is invalid.`,
  404: `Code expired, retry again`,
  409: 'The mobile number is already linked to an account. Please use a different number'
};

const OTPVerification = ({
  phone_number,
  nextStep,
  handleChange,
  setErrorCode,
  handleBack
}) => {
  const [otpErrorStatus, setOtpErrorStatus] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [resending, setResending] = useState(false);
  const [shouldResend, setShouldResend] = useState(false);

  const [phone, setPhone] = useState(phone_number);
  const [isTimerOn, setTimerON] = useState(true);

  const timerCompleted = () => {
    setTimerON(false);
  };

  const initialValues = {
    phone_number: phone,
    code: ''
  };
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.number()
      .integer()
      .typeError('Please Enter a valid phone number')
      .required('Phone number is required')
  });

  const updateState = phone => {
    setPhone(phone);
    handleChange({ phone_number: phone });
  };

  const verifyOtp = async (formik = {}) => {
    if (shouldResend) {
      resendOtp(phone_number, formik);
      return;
    }
    try {
      setSubmitting(true);
      const code = (formik.values && formik.values.code) || null;
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/onboard/user/verify`,
        { number: phone_number, code },
        getAuthHeader()
      );
      if (response && !response.error) {
        nextStep();
      }
    } catch (err) {
      if (err.response.status === 404 || err.response.status === 400) {
        setOtpErrorStatus(err.response.status);
        if (err.response.status === 404) {
          setShouldResend(true);
        }
      } else {
        setErrorCode(err.response.status);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const resendOtp = async (phone, formik) => {
    setResending(true);
    setOtpErrorStatus(false);
    formik.setFieldValue('code', '');
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/onboard/user/re-send-otp`,
        { phone_number: phone },
        getAuthHeader()
      );
    } catch (err) {
      setErrorCode(err.response.status);
      // TODO
    } finally {
      setResending(false);
      setShouldResend(false);
      setTimerON(true);
    }
  };

  return (
    <>
      <div className={styles.inputSection}>
        <img
          src={nurtureBoxLogo}
          alt="nurturebox"
          className={styles.nurtureBoxImg}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {formik => (
            <Form>
              <div className={styles.formParent}>
                <div className={styles.phoneVerification}>
                  <h4>Verify with OTP</h4>
                  <p className={styles.subHeading}>
                    NurtureBox unlocks a lot of potential with your mobile.
                  </p>
                  <div className={styles.numberField}>
                    <label htmlFor="Contact" className={styles.label}>
                      Contact
                    </label>
                    <PhoneInput
                      className={styles.phoneNumberInput}
                      name="phoneNumber"
                      placeholder="Enter your phone number"
                      value={phone}
                      onChange={updateState}
                      disabled
                    />
                    <p className={styles.editButton} onClick={handleBack}>
                      Edit
                    </p>
                  </div>
                  <FormikController
                    control="text"
                    name="code"
                    placeholder="Enter OTP here"
                    variant="filled"
                    label="Enter OTP"
                    maxLength={6}
                  />
                  {otpErrorStatus ? (
                    <p className={styles.otpError}>
                      {errorMessages[otpErrorStatus]}
                    </p>
                  ) : null}
                  <p></p>
                  <div className={styles.otpFormButton}>
                    <Button
                      label={
                        !submitting
                          ? shouldResend
                            ? 'Resend OTP'
                            : resending
                            ? 'Resending...'
                            : 'Verify OTP'
                          : 'Verifying...'
                      }
                      className={styles.submitBtn}
                      clickHandler={() => verifyOtp(formik)}
                      disabled={
                        (!shouldResend &&
                          (!formik.values.code ||
                            formik.values.code.length < 6)) ||
                        submitting ||
                        resending
                      }
                    />
                    {!shouldResend && (
                      <p>
                        Didn&apos;t receive OTP?
                        {!isTimerOn ? (
                          <span
                            className={styles.resend}
                            onClick={() => resendOtp(phone, formik)}
                          >
                            Resend again
                          </span>
                        ) : (
                          <Timer time={59} timerCompleted={timerCompleted} />
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  is_phone_verified: state.auth.is_phone_verified
});
OTPVerification.propTypes = {
  is_phone_verified: PropTypes.string
};

export default connect(mapStateToProps, {
  getMobileVerification
})(OTPVerification);
